/* eslint no-unused-vars: 0 */
/* global tw */
import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { jsx, css } from '@emotion/core';

import SubTitle from './subtitle';

const latestNewsContainer = css`
  ${tw`container mt-8`};
`;

const LatestNews = () => {
  const data = useStaticQuery(graphql`
    query latestNewsQuery {
      site: siteJson {
        latestNewsTitle
        latestNews
      }
    }
  `);
  const { latestNewsTitle, latestNews } = data.site;

  return (
    <div css={latestNewsContainer}>
      <SubTitle text={latestNewsTitle} />
      {latestNews.map((item, k) => (
        <p key={k}>{item}</p>
      ))}
    </div>
  );
};

export default LatestNews;
