/* eslint no-unused-vars: 0 */
/* global tw */
import React from 'react';
import { graphql } from 'gatsby';
import { jsx, css } from '@emotion/core';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';

import PageContainer from '../components/page-container';
import SEO from '../components/seo';
import Title from '../components/title';
import SubTitle from '../components/subtitle';
import Content from '../components/content';
import AddressCard from '../components/address-card';
import LatestNews from '../components/latest-news';
import FlowList from '../components/flow-list';

const carousel = css`
  ${tw`bg-grey-lighter container overflow-hidden`};
`;
const streamFlows = css`
  ${tw`container`};
`;

const IndexPage = ({ data }) => {
  const { frontmatter: fm, html: content } = data.page;
  const { fluid: headerImage } = data.headerImg.childImageSharp;

  return (
    <PageContainer>
      <SEO title={fm.title} description={fm.metaDescription} />
      <div css={carousel}>
        <Img
          title="Header Image"
          alt="Rainbow trout caught on a grashopper fly pattern"
          fluid={headerImage}
        />
      </div>
      <div className="section">
        <Title text={fm.title} />
        <div className="columns">
          <div className="column is-two-thirds">
            <Content html={content} />
            <div className="columns">
              <div className="column">
                <AddressCard />
              </div>
              <div className="column">
                <div css={tw`pt-4`}>
                  <img
                    src={fm.googleMapUrl}
                    css={tw`rounded`}
                    alt="Google map of The San Juan Angler in downtown Durango, CO"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="column is-one-third">
            <FlowList stations={fm.flowData} />
            <LatestNews />
          </div>
        </div>
      </div>
    </PageContainer>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    page: PropTypes.shape({
      html: PropTypes.string,
      frontmatter: PropTypes.shape({
        title: PropTypes.string,
        metaDescription: PropTypes.string,
        googleMapUrl: PropTypes.string,
        flowData: PropTypes.shape({
          stationId: PropTypes.string,
          stationName: PropTypes.string,
        }),
      }),
    }),
  }),
};

export default IndexPage;

export const query = graphql`
  query HomePageQuery {
    page: markdownRemark(
      frontmatter: { title: { eq: "The San Juan Angler" } }
    ) {
      html
      frontmatter {
        title
        metaDescription
        googleMapUrl
        flowData {
          stationId
          stationName
        }
      }
    }
    headerImg: file(relativePath: { eq: "images/rainbow-hopper-eat.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1400) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`;
