/* eslint no-unused-vars: 0 */
/* global tw */
import React from 'react';
import moment from 'moment';
import { graphql, useStaticQuery } from 'gatsby';
import { jsx, css } from '@emotion/core';
import PropTypes from 'prop-types';

import SubTitle from './subtitle';

const FlowList = props => {
  const data = useStaticQuery(graphql`
    query FlowListQuery {
      usgs: allUsgsWater {
        edges {
          node {
            name
            value
            dateTime
            stationId
          }
        }
      }
      cowater: allCoWater {
        edges {
          node {
            name
            value
            dateTime
            stationId
          }
        }
      }
    }
  `);
  const { edges: usgsFlows } = data.usgs;
  const { edges: cowaterFlows } = data.cowater;
  const allFlowData = [...usgsFlows, ...cowaterFlows];
  const asOfDate = moment().format('MMMM DD, YYYY');

  const getFlowValue = stationId => {
    // eslint-disable-next-line eqeqeq
    const flowObj = allFlowData.find(flow => flow.node.stationId == stationId);

    if (flowObj) {
      return flowObj.node.value;
    }

    console.log('Unable to find record for ', stationId);
  };

  const { stations } = props;

  return (
    <div>
      <SubTitle text="Stream Flows" />
      <h4 css={tw`pb-4 font-light text-base uppercase`}>As of: {asOfDate}</h4>
      {stations.map((station, k) => (
        <div css={tw`flex justify-between pb-2`} key={k}>
          <div css={tw`font-normal`}>{station.stationName}</div>
          <div>{getFlowValue(station.stationId)} cfs</div>
        </div>
      ))}
    </div>
  );
};

FlowList.propTypes = {
  stations: PropTypes.arrayOf(PropTypes.string),
};

export default FlowList;
